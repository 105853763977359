import * as Sentry from '@sentry/angular-ivy';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from '@gen/environments';

if (environment.production) {
  Sentry.init({
    dsn: 'https://e0142f0b16594aaa9262813a1f6dbfcf@sentry.gen.com.br/5',
    environment: 'production',
    integrations: [
      new Sentry.BrowserTracing({
        tracingOrigins: ['https://cupom.gen.com.br'],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    ],
    ignoreErrors: [
      'Firebase Storage',
      'ChunkLoadError',
      'The email address is already in use by another account.',
      'INTERNAL ASSERTION FAILED',
      'A network error',
      "The user's credential is no longer valid. The user must sign in again.",
      'Missing or insufficient permissions.',
      'Failed to get document because the client is offline.',
      'Attempt to get a record from database without an in-progress transaction',
    ],
    tracesSampleRate: 0.7,
  });

  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
